import React from "react"

export default function EnglishOurStory() {
  return (
    <>
      <h1>Our Story - WTF?! (What the f'real?!)</h1>

      <section>
        <p>
          You've discovered the greatest tasting frozen beverages on the planet!
          But how did we get our start?
        </p>
      </section>

      <section>
        <p>
          Back in the day, our founder Jim came up with the idea to build a
          blender that created happiness in a cup for people everywhere, with
          real milk and honest ingredients. In 2003 the first f'real blender was
          installed at a store in Tulsa, OK.
        </p>
      </section>

      <section>
        <p>
          We've come a long way since then, but our shakes and smoothies are
          still a chill and tasty blend-it-yourself (BIY) snack that you, yes,
          YOU, get to blend yourself at the f'real machine: Pick your flavor,
          pick your thickness and get ready for a f'really good experience.
        </p>
      </section>

      <section>
        <p>With over 20K locations worldwide, you can't miss us.</p>
      </section>
    </>
  )
}
